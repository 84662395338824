<template>
    <img class="photo" alt="Jonathan DePaula" src="../../assets/Jonathan.png">
</template>

<script>
export default {
    name: 'JPhoto',
}
</script>

<style scoped>
    .photo {
        border-radius: 50%;
        margin-top: 5rem;
        margin-bottom: 10rem;
    }
</style>