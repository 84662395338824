<template>
    <h1>Skills</h1>
    <div class="skills-container">
        <div>
            <JListItem logo="fa-brands fa-python" :description=python />
            <JListItem logo="fa-brands fa-vuejs" :description=vue :reverse=true />
            <JListItem logo="fa-brands fa-angular" :description=angular />
        </div>
        <div>
            <JListItem logo="fa-solid fa-folder" :description=agile />
            <JListItem logo="fa-solid fa-microscope" :description=testing :reverse=true />
            <JListItem logo="fa-solid fa-database" :description=database />
        </div>
    </div>
</template>

<script>
import JListItem from '../layout/JListItem.vue';

export default {
    name: 'JSkills',
    components: {
        JListItem,
    },
    data() {
        return {
            python: [
                {
                    title: "Skill",
                    text: "Python"
                },
                {
                    title: "Time",
                    text: "7 years"
                },
            ],
            vue: [
                {
                    title: "Skill",
                    text: "Vue"
                },
                {
                    title: "Time",
                    text: "2 years"
                },
            ],
            angular: [
                {
                    title: "Skill",
                    text: "Angular"
                },
                {
                    title: "Time",
                    text: "5 years"
                },
            ],
            agile: [
                {
                    title: "Skill",
                    text: "Agile"
                },
                {
                    title: "Time",
                    text: "7 years"
                },
            ],
            testing: [
                {
                    title: "Skill",
                    text: "Testing"
                },
                {
                    title: "Time",
                    text: "2 years"
                },
            ],
            database: [
                {
                    title: "Skill",
                    text: "PostgreSQL"
                },
                {
                    title: "Time",
                    text: "2 years"
                },
            ],
        }
    }
}
</script>

<style scoped>
    .skills-container {
        display: flex;
        justify-content: center;
        margin-bottom: 5rem;
    }
</style>