<template>
  <div>
    <JHeader />
    <JAbout />
    <JEducation />
    <JWork />
    <JSkills />
    <JContact />
    <JFooter />
  </div>
</template>

<script>
import JHeader from './layout/JHeader.vue'
import JFooter from './layout/JFooter.vue'
import JEducation from './information/JEducation.vue'
import JWork from './information/JWork.vue'
import JContact from './form/JContact.vue'
import JAbout from './information/JAbout.vue'
import JSkills from './information/JSkills.vue'

export default {
  name: 'JHome',
  components: {
    JHeader,
    JFooter,
    JEducation,
    JWork,
    JContact,
    JAbout,
    JSkills,
  },
  props: {
    msg: String
  }
}
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
