<template>
    <div class="education-container">
        <h1>Education</h1>
        <JListItem logo="fa-solid fa-graduation-cap" :description=masters />
        <JListItem logo="fa-solid fa-graduation-cap" :description=bachelors :reverse=true />
    </div>
</template>

<script>
import JListItem from '../layout/JListItem.vue';

export default {
    name: 'JEducation',
    components: {
        JListItem,
    },
    data() {
        return {
            masters: [
                {
                    title: "Institution",
                    text: "Arizona State University"
                },
                {
                    title: "Degree",
                    text: "Master's of Computer Science"
                },
                {
                    title: "Major",
                    text: "Computer Science"
                },
                {
                    title: "Dates",
                    text: "September 2021 - May 2023"
                },
                {
                    title: "GPA",
                    text: "3.86"
                },
            ],
            bachelors: [
                {
                    title: "Institution",
                    text: "Arizona State University"
                },
                {
                    title: "Degree",
                    text: "Bachelors's of Science"
                },
                {
                    title: "Major",
                    text: "Computer Science"
                },
                {
                    title: "Dates",
                    text: "September 2012 - May 2018"
                },
                {
                    title: "GPA",
                    text: "3.23"
                },
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .education-container {
        margin-bottom: 15rem;
    }
</style>