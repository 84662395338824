<template>
    <div class="work-container">
        <h1>Work History</h1>
        <JListItem logo="fa-solid fa-computer" :description=skilljar />
        <JListItem logo="fa-solid fa-computer" :description=hawaiian :reverse=true />
        <JListItem logo="fa-solid fa-computer" :description=western />
    </div>
</template>

<script>
import JListItem from '../layout/JListItem.vue';

export default {
    name: 'JWork',
    components: {
        JListItem,
    },
    data() {
        return {
            skilljar: [
                {
                    title: "Company",
                    text: "Skilljar"
                },
                {
                    title: "Job Title",
                    text: "Software Development Engineer"
                },
                {
                    title: "Location",
                    text: "Mesa, AZ (Remote)"
                },
                {
                    title: "Dates",
                    text: "April 2022 - current"
                },
                {
                    title: "Skills",
                    text: "Python, Django, Vue"
                },
            ],
            hawaiian: [
                {
                    title: "Company",
                    text: "Hawaiian Airlines"
                },
                {
                    title: "Job Title",
                    text: "Software Development Engineer"
                },
                {
                    title: "Location",
                    text: "Tempe, AZ"
                },
                {
                    title: "Dates",
                    text: "July 2019 - April 2022"
                },
                {
                    title: "Skills",
                    text: "Angular, Mulesoft, C#"
                },
            ],
            western: [
                {
                    title: "Company",
                    text: "Western Digital"
                },
                {
                    title: "Job Title",
                    text: "Firmware Engineer"
                },
                {
                    title: "Location",
                    text: "Chandler, AZ"
                },
                {
                    title: "Dates",
                    text: "May 2018 - July 2019"
                },
                {
                    title: "Skills",
                    text: "C, C++, Python"
                },
            ],
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .work-container {
        margin-bottom: 15rem;
    }
</style>