<template>
    <div v-bind:class="(reverse) ? 'container-reverse' : 'container'">
        <JIcon class="item" :icon=logo :href=link color="black" size="5.5rem" />
        <div class="item description_container">
            <div class="description_item" v-for="item in description" :key="item">
                <strong>{{ item.title }}</strong>: {{ item.text }}<br>
            </div>
        </div>
    </div>
</template>

<script>
import JIcon from '../utils/JIcon.vue';

export default {
    name: 'JListItem',
    components: {
        JIcon
    },
    props: {
        logo: String,
        description: Array,
        reverse: Boolean,
    }
}
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .container-reverse {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 100px;
        flex-direction: row-reverse;
    }

    .item {
        margin: 0px 100px 0px 100px;
    }

    .description_container {
        /* display: flex;
        align-items: center; */
    }

    .description_item {
        text-align: initial;
    }
</style>