<template>
    <div v-if="!closedBanner" class="alert-container">
        <div class="alert" :style="style">
            <span class="closebtn" @click="closeBanner">&times;</span>
            {{ text }}
        </div>
    </div>
</template>
    
<script>
export default {
    name: 'JAlert',
    props: {
        text: String,
        type: String,
    },
    data () {
        return {
            alertType: '',
            closedBanner: false,
        }
    },
    computed: {
        style() {
            let style = '';
            if (this.type === 'error') {
                style = 'background-color: red;'
            }
            else if (this.type === 'success') {
                style = 'background-color: #56d656;'
            }

            return style;
        }
    },
    methods: {
        closeBanner() {
            this.closedBanner = true;
        }
    }
}
</script>

<style scoped>
    .alert-container {
        display: flex;
        justify-content: center;
    }

    .alert {
        padding: 20px;
        color: white;
        margin-bottom: 15px;
        width: 80%;
    }

    /* The close button */
    .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
    }

    /* When moving the mouse over the close button */
    .closebtn:hover {
        color: black;
    }
</style>
    