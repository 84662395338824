<template>
    <div class="container">
        <JIcon :icon=icon :href=link color="gray" size="2.5rem" />
    </div>
</template>
    
<script>
import JIcon from '../utils/JIcon.vue';

export default {
    name: 'JSocials',
    components: {
        JIcon,
    },
    props: {
        icon: String,
        link: String,
    },
}
</script>

<style scoped>
    .container {
        border-radius: 50%;
        background-color: white;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
    