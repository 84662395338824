<template>
    <div class="header">
        <div class="name_and_title_wrapper">
            <h1 style="text-align: initial;">Jonathan DePaula</h1>
            <h3 style="margin: unset;">Software Development Engineer</h3>
            <div class="location">
                <JIcon 
                    class="icon"
                    icon="fa-solid fa-location-pin" 
                    href="https://www.google.com/maps/place/Mesa,+AZ/@33.3983618,-111.8977737,10.94z/data=!4m6!3m5!1s0x872ba7c2c0c592c1:0xd10fc215694e771b!8m2!3d33.4151843!4d-111.8314724!16zL20vMHFwbjk?entry=ttu"
                    color="white" 
                />
                <h3>Mesa, Arizona</h3>
            </div>
        </div>
        <div> 
            <JMenu /> 
        </div>
    </div>
</template>

<script>
import JIcon from '../utils/JIcon.vue';
import JMenu from './JMenu.vue';

export default {
    name: 'JHeader',
    components: {
        JIcon,
        JMenu,
    },
}
</script>

<style scoped>
    .header {
        padding: 60px 0px;
        background-image: linear-gradient(to right, gray , teal);
        display: flex;
        justify-content: space-around;
    }

    .name_and_title_wrapper {
        color: white;
    }

    .location {
        display: flex;
        align-items: center;
        color: white;
    }

    .icon {
        padding-right: 1rem;
    }
</style>
    