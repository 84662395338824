<template>
  <a v-bind:href="href">
    <button>{{ text }}</button>
  </a>
</template>
  
<script>
export default {
  name: 'JButton',
  props: {
    text: String,
    href: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  