<template>
    <input 
    :placeholder=placeholder
    :value="internalValue"
    @input="onInput">
</template>

<script>
    export default {
    name: 'JField',
    props: {
        placeholder: String,
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            internalValue: this.value
        }
    },
    methods: {
        onInput(event) {
            this.internalValue = event.target?.value;
            this.$emit('update:value', this.internalValue);
        }
    }
}
</script>

<style scoped>
    input {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
    }
</style>
    