<template>
    <h1 v-if="!emailSent && !emailError">Contact</h1>
    <div class="email-container" v-if="emailSent">
        <JAlert text="Email sent successfully" type="success" />
    </div>
    <div class="email-container" v-if="emailError">
        <JAlert text="There was an error sending the email.  Please try again later." type="error" />
    </div>
    <div v-if="!emailSent && !emailError" class="contact-container">
        <div class="contact-fields-container">
            <JField 
                placeholder="name"
                v-bind:value="nameFormValue" 
                @update:value="registerNameChanges"     
            />
            <JField placeholder="email"
                v-bind:value="emailFormValue" 
                @update:value="registerEmailChanges"   
            />
            <JField placeholder="phone number"
                v-bind:value="phoneFormValue" 
                @update:value="registerPhoneChanges"  
            />
            <JTextArea
                v-bind:value="messageFormValue" 
                @update:value="registerMessageChanges"  
            />
        </div>
        <JButton text="Submit" @click="submit"/>
    </div>
</template>

<script>
import JButton from './JButton.vue'
import JField from './JField.vue'
import JAlert from '../layout/JAlert.vue'
import JTextArea from './JTextArea.vue'
import axios from 'axios'

export default {
    name: 'JContact',
    components: {
        JButton,
        JField,
        JAlert,
        JTextArea,
    },
    data() {
        return {
            nameFormValue: '',
            emailFormValue: '',
            phoneFormValue: '',
            messageFormValue: '',
            emailSent: false,
            emailError: false,
        }
    },
    methods: {
        registerNameChanges(value) {
            this.nameFormValue = value;
        },
        registerEmailChanges(value) {
            this.emailFormValue = value;
        },
        registerPhoneChanges(value) {
            this.phoneFormValue = value;
        },
        registerMessageChanges(value) {
            this.messageFormValue = value;
        },
        submit() {
            const requestPayload = {
                operation: "sendInquiryEmail",
                name: this.nameFormValue,
                emailAddress: this.emailFormValue,
                phoneNumber: this.phoneFormValue,
                message: this.messageFormValue,
            }

            axios.post('https://sztj44zvxa.execute-api.us-east-1.amazonaws.com/PROD/sendinquiryemail', requestPayload)
                .then(function() {
                    this.emailSent = true;
                }.bind(this))
                .catch(() => {
                    this.emailError = true;
                });
        },
    }
}
</script>

<style scoped>
    .email-container {
        margin-bottom: 15rem;
    }

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15rem;
    }

    .contact-fields-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25rem;
        padding: 0px 100px;
    }
</style>