<template>
    <div class="about-container">
        <JPhoto image="../../assets/Jonathan.png" />
        <p class="message">Hello, my name is Jonathan.  Welcome to my page!  Please feel free to take a look around and drop a message in the contact form if you would like to talk.</p>
    </div>
</template>

<script>
import JPhoto from './JPhoto.vue';

export default {
    name: 'JAbout',
    components: {
        JPhoto,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .about-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .message {
        font-weight: 400;
        font-size: 21px;
        width: 30%;
    }
</style>