<template>
    <div class="footer">
        <JSocials class="socials" icon="fa-brands fa-linkedin" link="https://www.linkedin.com/in/jonathan-depaula-b66936b2/" />
        <JSocials class="socials" icon="fa-brands fa-square-instagram" link="https://www.instagram.com/jonadepaula/" />
    </div>
</template>

<script>
import JSocials from '../utils/JSocials.vue';

export default {
    name: 'JFooter',
    components: {
        JSocials,
    },
}
</script>

<style scoped>
    .footer {
        padding: 60px 0px;
        background-image: linear-gradient(to right, gray , lightblue);
        display: flex;
        justify-content: center;
        position: relative;
        bottom: 0px;
        width: 100%;
    }

    .socials {
        margin: 0px 15px 0px 15px;
    }
</style>
    