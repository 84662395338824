<template>
    <a v-bind:href="href">
        <i v-bind:class="icon" :style="style"></i>
    </a>
</template>
    
<script>
export default {
    name: 'JIcon',
    props: {
        icon: String,
        href: String,
        color: String,
        size: String,
    },
    computed: {
        style() {
            let styling = '';

            if (this.color) {
                styling += 'color: ' + this.color + '; ';
            }

            if (this.size) {
                styling += 'font-size: ' + this.size;
            }

            return styling
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* a {
        padding-right: 1rem;
    } */
</style>
    