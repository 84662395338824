<template>
    <textarea rows="4" cols="50" 
        :value="internalValue"
        @input="onInput">
    </textarea>
</template>

<script>
    export default {
    name: 'JTextArea',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            internalValue: this.value
        }
    },
    methods: {
        onInput(event) {
            this.internalValue = event.target?.value;
            this.$emit('update:value', this.internalValue);
        }
    }
}
</script>

<style scoped>
    textarea {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
    }
</style>
    