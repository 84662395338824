<template>
    <div >
        <span @click="clickEd()">Education</span>
        <span @click="clickWork()">Work History</span>
        <span @click="clickSkills()">Skills</span>
        <span @click="clickContact()">Contact</span>
    </div>
</template>

<script>
export default {
    name: 'JMenu',
    methods: {
        clickEd() {
            window.scrollTo({
                top: 900,
                left: 0,
                behavior: 'smooth'
            });
        },
        clickWork() {
            window.scrollTo({
                top: 1600,
                left: 0,
                behavior: 'smooth'
            });
        },
        clickSkills() {
            window.scrollTo({
                top: 2500,
                left: 0,
                behavior: 'smooth'
            });
        },
        clickContact() {
            window.scrollTo({
                top: 3000,
                left: 0,
                behavior: 'smooth'
            });
        },
    }
}
</script>

<style scoped>
    span {
        float: left;
        color: #f2f2f2;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 17px;
    }

    /* Change the color of links on hover */
    span:hover {
        background-color: #ddd;
        color: black;
        cursor: pointer;
    }

    /* Add a color to the active/current link */
    span.active {
        background-color: #04AA6D;
        color: white;
    }
</style>